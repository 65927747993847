// System
import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Container, Nav, Navbar, Modal, Button } from "react-bootstrap";
import { Login, Visibility } from "@mui/icons-material";

// App
import "./css/Header.css";
import { IRoutes, ROUTES } from "../../helpers/Routes";

interface IMenuItem {
  key: string;
  label: string;
  link: keyof IRoutes;
}

const menuItems: Array<IMenuItem> = [
  {
    key: "about_us",
    label: "Про нас",
    link: "ABOUT_US",
  },
  {
    key: "gas_station",
    label: "АЗС",
    link: "GAS_STATION",
  },
  {
    key: "wholesale_sales",
    label: "Оптові продажі",
    link: "WHOLESALE_SALES",
  },
  {
    key: "quality",
    label: "Якість",
    link: "QUALITY",
  },
  // {
  //   key: "partners",
  //   label: "Партнери",
  //   link: "PARTNERS",
  // },
  {
    key: "charity",
    label: "Благодійність",
    link: "CHARITY",
  },
];

export const Header: React.FC = () => {
  const routes: IRoutes = ROUTES;
  const location: string = useLocation().pathname;
  const [currentLocation, setCurrentLocation] = useState(
    useLocation().pathname,
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const activeNavLinkColor: string = "#432C7A";
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState(false);

  /**
   * Close menu & scroll to top smoothly
   * @param {boolean} open
   */
  const onMenuStateChanged = (open: boolean): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsMenuOpen(open);
  };

  /**
   * Close expanded navbar programmatically
   */
  const closeMenu = useCallback(() => {
    const closeEl: Element | null = document.querySelector(".close-icon");
    (closeEl as any)?.click();
    onMenuStateChanged(false);
    setCurrentLocation(location);
  }, [location]);

  /**
   * Close modal window
   */
  const closeModal = (): void => setShow(false);

  /**
   * Show modal window
   */
  const showModal = useCallback((): void => setShow(true), []);

  /**
   * @param {*} event
   */
  const login = (event: any): void => {
    event.preventDefault();
    closeModal();
  };

  useEffect(() => {
    if (isMenuOpen && currentLocation !== location) {
      closeMenu();
    }
  }, [isMenuOpen, currentLocation, location, closeMenu]);

  return (
    <div className={isMenuOpen ? "Header fixed" : "Header"}>
      <Container className="navbar-logo">
        <Link className="menu-link logo" to={routes.MAIN}>
          <img
            className="logo-img"
            src="/images/carbon-logo.png"
            alt="Логотип Карбон-Плюс"
          />
        </Link>
      </Container>
      <Navbar collapseOnSelect expand="xl" variant="dark">
        <Container className="navbar-container">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="navbar-controls"
          >
            {/* expand icon */}
            <span
              className="navbar-toggler-icon"
              onClick={() => onMenuStateChanged(true)}
            />
            {/* collapse icon */}
            <div
              className="close-icon py-1"
              onClick={() => onMenuStateChanged(false)}
            >
              ✖
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              {menuItems.map((item: IMenuItem) => (
                <div className="block" key={item.key}>
                  <NavLink
                    className="menu-link"
                    style={({ isActive }) => ({
                      color: !isActive ? "#fff" : activeNavLinkColor,
                    })}
                    to={routes[item.link]}
                  >
                    {item.label}
                  </NavLink>
                </div>
              ))}
              <div className="block no-border img-block">
                <Login className="login-img" onClick={showModal} />
              </div>
              <div className="block no-border">
                <NavLink
                  className="menu-link btn-like"
                  style={({ isActive }) => ({
                    color: !isActive ? "#fff" : activeNavLinkColor,
                  })}
                  to={routes.CONTACT_US}
                >
                  <span>{"Напишіть нам"}</span>
                </NavLink>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        show={show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        centered
        restoreFocus={true}
        scrollable={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Увійти в особистий кабінет</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-block">
            <label htmlFor="login">Логін</label>
            <div className="modal-input">
              <input type="text" id="login" />
            </div>
          </div>
          <div className="modal-body-block">
            <label htmlFor="password">Пароль</label>
            <div className="modal-input">
              <input
                className="pass-input"
                type={showPass ? "text" : "password"}
                id="password"
              />
              <Visibility
                className={"show-pass-btn" + (!showPass ? "" : " show")}
                onClick={(): void => setShowPass(!showPass)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"light"} onClick={closeModal} autoFocus>
            Скасувати
          </Button>
          <Button variant={"primary"} disabled={true} onClick={login}>
            Увійти
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
