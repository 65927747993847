import React, { useEffect } from "react";
import "./css/Quality.css";
import { fadeOnScroll } from "../../helpers/animations";
import { ContentCarousel } from "../ContentCarousel/ContentCarousel";

export const Quality: React.FC = () => {
  const pageTitle: string = "Якість";

  useEffect(() => {
    document.title = pageTitle;
    window.scrollTo(0, 0);

    fadeOnScroll();
  }, []);

  return (
    <div className="Quality">
      <section className="section">
        <div className="section__container">
          <div className="block">
            <img src="/images/tanker.jpg" alt="quality" />
          </div>
          <div className="block">
            <h5>
              Ми пишаємося тим що надаємо своїм клієнтам лише найкращу якість
              палива та бездоганний сервіс.
            </h5>
            <h5>
              Наші стандарти якості відповідають найвищим європейським
              стандартам, і ми маємо всі необхідні документи, сертифікати та
              ліцензії, що підтверджують це.
            </h5>
            <h5>
              Кожна крапля палива на АЗС CARBON+ пройшла ретельну перевірку і
              відповідає найсуворішим вимогам якості. Ми піклуємося про
              надійність вашого автомобіля та його ефективну роботу. Крім того,
              наша команда професіоналів завжди готова надати Вам консультацію
              щодо оптимального вибору палива та його використання.
            </h5>
            <h5>
              Запевняємо Вас, що на нашій АЗС паливні насоси є офіційно
              сертифікованими та підлягають регулярній перевірці. Ми
              дотримуємося всіх правил безпеки та стандартів екологічної
              сумісності, щоб забезпечити чисте навколишнє середовище для нас і
              наших майбутніх поколінь.
            </h5>
            <h5>
              Обираючи CARBON+ Ви маєте впевненість в якості палива та
              надійності обслуговування.
            </h5>
            <h5>Довіряйте професіоналам!</h5>
          </div>
        </div>
      </section>
    </div>
  );
};
