import React, { useEffect } from "react";
import "./css/GasStation.css";
import { ContentCarousel, IStep } from "../ContentCarousel/ContentCarousel";
import { fadeOnScroll } from "../../helpers/animations";

const fuelSteps: IStep[] = [
  {
    h2: "1 крок",
    h2Shadow: "black",
    p: "Укласти договір з CARBON+",
    pShadow: "black",
    img: <img src="/images/refilling.jpg" alt="gas station" />,
  },
  {
    h2: "2 крок",
    h2Shadow: "black",
    p: "Оплатити необхідний об'єм палива",
    pShadow: "black",
    img: <img src="/images/refilling.jpg" alt="gas station" />,
  },
  {
    h2: "3 крок",
    h2Shadow: "black",
    p: "Отримати відомість за якою будуть заправлятися Ваші водії",
    pShadow: "black",
    img: <img src="/images/refilling.jpg" alt="gas station" />,
  },
];

const guaranteeSteps: IStep[] = [
  {
    h2: "Сертифіковане пальне",
    h2Shadow: "black",
    p: "Безперебійна робота Вашого автопарку",
    pColor: "white",
    pShadow: "black",
    img: <img src="/images/logistic.jpg" alt="logistic" />,
  },
  {
    h2: "Витрати",
    h2Color: "black",
    h2Shadow: "white",
    p: "Вигідні умови співпраці, програми лояльності та знижки",
    pColor: "yellow",
    pShadow: "black",
    img: <img src="/images/discounts.jpg" alt="discounts" />,
  },
  {
    h2: "Ефективне планування",
    h2Shadow: "black",
    p: "Швидка та зручна заправка",
    pShadow: "black",
    img: <img src="/images/fuel.jpg" alt="refilling" />,
  },
];

export const GasStation: React.FC<any> = () => {
  const pageTitle: string = "АЗС";

  useEffect(() => {
    document.title = pageTitle;
    window.scrollTo(0, 0);

    fadeOnScroll();
  }, []);

  return (
    <div className="GasStation">
      <section className="section">
        <div className="section__container">
          <div className="block">
            <ContentCarousel type="slide" steps={guaranteeSteps} />
          </div>
          <div className="block">
            <h3>
              З нами Ви забудете про зайві турботи щодо пального, адже CARBON+
              гарантує:
            </h3>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="section__container">
          <div className="block">
            <ContentCarousel type="fade" steps={fuelSteps} />
          </div>
          <div className="block">
            <h3>
              Якщо Ви маєте власний автопарк та мрієте про ефективну економію на
              пальному - команда CARBON+ готова допомогти Вам в цьому.
            </h3>
            <h3>
              Ми пропонуємо забезпечити Ваш бізнес якісним пальним на вигідних
              умовах.
            </h3>
            <h3>
              Для цього потрібно зробити лише <span>три</span> кроки
            </h3>
          </div>
        </div>
      </section>
      <h3>Також на нашій АЗС доступні колонки з продажу AdBlue.</h3>
    </div>
  );
};
