import React, { ReactNode, useEffect } from "react";
import "./css/Charity.css";
import { PDFReader } from "../PDFReader/PDFReader";
import { YoutubeEmbedded } from "../YoutubeEmbed/YoutubeEmbedded";
import { Box, Tab, Tabs } from "@mui/material";
import { a11yProps, TabPanel } from "../TabElements/TabElements";
import { v4 as uuid } from "uuid";
import {
  IImageListItem,
  ImageListComponent,
} from "../ImageListComponent/ImageListComponent";

interface ITab {
  name: string;
  content: ReactNode;
}

const pageTitle: string = "Благодійність";
const docURL: string = "/docs/gratitudes.pdf";
const docHeight: number = 700;
const videoEmbedSrc: string = "4Zg3OlljZms";

const itemsData: IImageListItem[] = [
  {
    img: "/images/soccer/team-in-hall.jpg",
    title: "Фут-зал",
    rows: 2,
    cols: 2,
  },
  {
    img: "/images/soccer/logo.jpg",
    title: "Логотип",
    contain: true,
  },
  {
    img: "/images/soccer/cup.jpg",
    title: "Кубок",
  },
  {
    img: "/images/soccer/medals.jpg",
    title: "Медалі",
    cols: 2,
  },
  {
    img: "/images/soccer/cup-hd.jpg",
    title: "Кубок",
    rows: 4,
    cols: 4,
  },
  {
    img: "/images/soccer/champions.jpg",
    title: "Чемпіони",
    rows: 2,
    cols: 2,
  },
  {
    img: "/images/soccer/team.jpg",
    title: "Команда",
    rows: 2,
    cols: 2,
  },
  {
    img: "/images/soccer/tournament.jpg",
    title: "Турнір",
    rows: 4,
    cols: 4,
  },
];

const tabs: Array<ITab> = [
  {
    name: "Волонтерство",
    content: <YoutubeEmbedded embeddedId={videoEmbedSrc} />,
  },
  {
    name: "Спорт",
    content: (
      <div className="images-list">
        <ImageListComponent itemsData={itemsData} />
      </div>
    ),
  },
  {
    name: "Здобутки",
    content: <PDFReader file={docURL} height={docHeight} />,
  },
];

export const Charity: React.FC = () => {
  const [index, setIndex] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) =>
    setIndex(newValue);

  useEffect(() => {
    document.title = pageTitle;
  }, []);

  return (
    <div className="Charity">
      <Box
        sx={{
          maxWidth: {
            xs: 320,
            sm: 480,
            md: 640,
            lg: 960,
            xl: 1300,
          },
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={index}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {tabs.map((tab: ITab, i: number) => (
            <Tab label={tab.name} {...a11yProps(i)} key={uuid()} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab: ITab, i: number) => (
        <TabPanel value={index} index={i} key={uuid()}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};
