import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "./css/PDFReader.css";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

interface IPDFReaderProps {
  file: string;
  height: number;
}

export const PDFReader: React.FC<IPDFReaderProps> = ({
  file,
  height,
}: IPDFReaderProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: any): void =>
    setNumPages(numPages);
  const changePage = (offset: number): void =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  const prevPage = (): void => changePage(-1);
  const nextPage = (): void => changePage(1);

  return (
    <div className="PDFReader">
      <Document
        className="document"
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page height={height} pageNumber={pageNumber} />
      </Document>
      {numPages ? (
        <div className="btn-block">
          <button
            className="btn prev-btn"
            disabled={pageNumber === 1}
            onClick={prevPage}
          >
            {<ArrowBack />}
          </button>
          <p>
            {pageNumber} з {numPages}
          </p>
          <button
            className="btn next-btn"
            disabled={pageNumber === numPages}
            onClick={nextPage}
          >
            {<ArrowForward />}
          </button>
        </div>
      ) : null}
    </div>
  );
};
