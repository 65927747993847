export const fadeOnScroll = () => {
  const containers: NodeListOf<Element> = document.querySelectorAll(
    ".section__container",
  );

  containers.forEach((container) => {
    const windowHeight: number = window.innerHeight;
    const elementTop: number = container.getBoundingClientRect().top;
    const elementVisible: number = 150;

    if (elementTop < windowHeight - elementVisible) {
      container.classList.add("active");
    } // else { containers[i].classList.remove("active"); }
  });
};
