import React, { ReactNode, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./css/ContentCarousel.css";
import { v4 as uuid } from "uuid";

type TColors = "white" | "black" | "yellow" | "red" | "blue" | "green";

export interface IStep {
  h2: string;
  h2Color?: TColors;
  h2Shadow?: TColors;
  p: string;
  pColor?: TColors;
  pShadow?: TColors;
  img: ReactNode;
}

interface IContentCarousel {
  type: "slide" | "fade";
  steps: IStep[];
  children?: ReactNode;
}

const getClassName = (
  color: TColors | undefined,
  shadow: TColors | undefined,
): string => {
  return color || shadow ? `color-${color} shadow-${shadow}` : "";
};

export const ContentCarousel = ({ type, steps }: IContentCarousel) => {
  const [index, setIndex] = useState<number>(0);

  const handleSelect = (selectedIndex: number) => setIndex(selectedIndex);

  return (
    <div className="ContentCarousel">
      <Carousel
        slide={type === "slide"}
        fade={type === "fade"}
        activeIndex={index}
        keyboard={true}
        touch={true}
        onSelect={handleSelect}
      >
        {steps.map(
          ({ h2, h2Color, h2Shadow, p, pColor, pShadow, img }: IStep) => (
            <Carousel.Item key={uuid()}>
              {img}
              <Carousel.Caption>
                <h2 className={getClassName(h2Color, h2Shadow)}>{h2}</h2>
                <p className={getClassName(pColor, pShadow)}>{p}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ),
        )}
      </Carousel>
    </div>
  );
};
