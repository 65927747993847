import React from "react";
import "./css/Popup.css";
import {Box, CircularProgress, ClickAwayListener} from "@mui/material";
import {Button} from "react-bootstrap";
import {Subject} from "rxjs";

export const confirmed$: Subject<void> = new Subject<void>();

export const Popup: React.FC<any> = (
    { isInvalid, btnText, message, isLoading }
) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => setOpen((prev: boolean) => !prev);
    const closePopper = () => setOpen(false);

    const onConfirm = (): void => {
        confirmed$.next();
        closePopper();
    }

    return (
        <ClickAwayListener onClickAway={closePopper}>
            <Box sx={{ position: 'relative' }}>
                <Button variant={!isLoading ? "success" : "light" }
                        onClick={handleClick}
                        disabled={isInvalid}
                >
                    {
                        !isLoading
                        ? btnText
                        : <CircularProgress color="success"
                                            size={25}
                                            thickness={7.5}
                          />
                    }
                </Button>

                {open ? (
                    <Box className="popup-container">
                        <Box className="popup-container--body">
                            {message}
                        </Box>
                        <Box className="popup-container--footer">
                            <Button className={"btn btn-cancel"}
                                    variant="secondary"
                                    onClick={closePopper}>
                                Скасувати
                            </Button>
                            <Button className={"btn btn-ok"}
                                    variant="primary"
                                    onClick={onConfirm}>
                                Підтвердити
                            </Button>
                        </Box>
                    </Box>
                ) : null}
            </Box>
        </ClickAwayListener>
    )
}
