import React from "react";
import {Form} from "react-bootstrap";
import emailjs from "emailjs-com";
import "./css/ContactUs.css";
import {confirmed$, Popup} from "../Popup/Popup";
import {Subscription} from "rxjs";

export const ContactUs: React.FC = () => {
    const [errors, setErrors] = React.useState({
        senderName: '',
        senderEmail: '',
        text: ''
    });
    const [formData, setFormData] = React.useState({
        senderName: '',
        senderEmail: '',
        text: ''
    });
    const [isFormTouched, setIsFormTouched] = React.useState(false);
    const [isInvalid, setIsInvalid] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const pageTitle: string = 'Напишіть нам';
    const btnText: string = 'Надіслати';
    const message: string = 'Ви впевнені, що хочете надіслати введені дані? Ця дія незворотня, форму зворотнього зв\'язку буде очищено';

    /**
     * Update form data
     *
     * @param {string} prop
     * @param {string} val
     */
    const updateFormData = (prop: string, val: string): void => {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [prop]: val?.trim() || ''
            };
        });
        setIsFormTouched(true);
    };

    /**
     * Send email
     */
    const sendEmail = React.useCallback((): void => {
        setLoading(true);
        const templateParams: { message: string } = {
            message: `Повідомлення з сайту "carbon-plus" - ${formData.senderEmail}: ${formData.text}. З повагою, ${formData.senderName}`
        };

        emailjs.send('service_dguak49', 'template_erwexld', templateParams, '6-bEny81ef7z5OoQn')
            .then(() => {
                setLoading(false);
                clearForm();
            }, (err) => {
                setLoading(false);
                // todo: use snackbar to tell user 'service unavailable, retry later'
                console.error(err.text);
            });
    }, [formData]);

    /**
     * Clear the form
     */
    const clearForm = (): void => {
        setFormData({
            senderName: '',
            senderEmail: '',
            text: ''
        });
        setErrors({
            senderName: '',
            senderEmail: '',
            text: ''
        });
        setIsFormTouched(false);
        setIsInvalid(false);
    };

    const subscriptionRef = React.useRef(new Subscription());

    React.useEffect(() => {
        document.title = pageTitle;
        subscriptionRef.current = confirmed$.subscribe(sendEmail);

        const values: Array<string> = Object.values(formData);

        if (values.some((value: string) => !value)) {
            setIsInvalid(true);
            setErrors({
                senderName: values[0] || '',
                senderEmail: values[1] || '',
                text: values[2] || ''
            });
        } else {
            setIsInvalid(false);
        }

        return () => subscriptionRef.current.unsubscribe();
    }, [sendEmail, formData]);

    return (
        <div className="ContactUs">
            <h3 className="form-title">
                Ми відповімо Вам так швидко, як тільки зможемо
            </h3>
            <Form className="form"
                  noValidate
                  validated={ isFormTouched && isInvalid }
            >
                <Form.Group controlId="formName"
                            className="form__group">
                    <Form.Control className="field"
                                  type="text"
                                  placeholder="Введіть ім'я *"
                                  required
                                  autoComplete="off"
                                  value={ formData.senderName }
                                  isInvalid={ isFormTouched && isInvalid && !errors.senderName }
                                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => updateFormData('senderName', evt.target.value)} />
                    {
                        isFormTouched && isInvalid && !errors.senderName &&
                        <Form.Control.Feedback type="invalid">
                            Будь ласка, введіть ім'я.
                        </Form.Control.Feedback>
                    }
                </Form.Group>
                <Form.Group controlId="formEmail"
                            className="form__group">
                    <Form.Control className="field"
                                  type="email"
                                  placeholder="Введіть email *"
                                  required
                                  value={ formData.senderEmail }
                                  isInvalid={ isFormTouched && isInvalid && !errors.senderEmail }
                                  onChange={ (evt: React.ChangeEvent<HTMLInputElement>) => updateFormData('senderEmail', evt.target.value) } />
                    {
                        isFormTouched && isInvalid && !errors.senderEmail &&
                        <Form.Control.Feedback type="invalid">
                            Будь ласка, введіть електронну пошту.
                        </Form.Control.Feedback>
                    }
                </Form.Group>
                <Form.Group controlId="formMessage"
                            className="form__group">
                    <Form.Control className="field textarea"
                                  type="text"
                                  placeholder="Текст повідомлення *"
                                  required
                                  as="textarea"
                                  rows={3}
                                  value={ formData.text }
                                  isInvalid={ isFormTouched && isInvalid && !errors.text }
                                  onChange={ (evt: React.ChangeEvent<HTMLInputElement>) => updateFormData('text', evt.target.value) } />
                    {
                        isFormTouched && isInvalid && !errors.text &&
                        <Form.Control.Feedback type="invalid"
                                               className="textarea-feedback">
                            Будь ласка, введіть текст повідомлення.
                        </Form.Control.Feedback>
                    }
                </Form.Group>
            </Form>
            {/* confirming popup */}
            <Popup isInvalid={isInvalid}
                   btnText={btnText}
                   message={message}
                   isLoading={loading}
            />
        </div>
    )
}
