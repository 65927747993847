// System
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// App
import "./css/Home.css";
import { ROUTES } from "../../helpers/Routes";
import { fadeOnScroll } from "../../helpers/animations";

interface ISection {
  key: string;
  imageSrc: string;
  url: string;
  text: string;
  btnText: string;
}

const sections: Array<ISection> = [
  {
    key: "desc_gas_station",
    imageSrc: "/images/fuel.jpg",
    url: ROUTES.GAS_STATION,
    text: `
      Наша компанія є однією з провідних на ринку нафтопродуктів у Черкаській області.  
      Вже більше 8 років ми забезпечуємо своїх клієнтів лише якісним паливом.
      Ми маємо тисячі постійних клієнтів, яким реалізуємо в роздріб та оптом понад 5 млн л пального в рік.
      Маємо власну АЗС, що розташована в м. Черкаси, вул. Нечуя-Левицького, 16.
    `,
    btnText: "АЗС",
  },
  {
    key: "desc_wholesale_sales",
    imageSrc: "/images/fuel-industry.jpg",
    url: ROUTES.WHOLESALE_SALES,
    text: `
       З 2022-го року є прямими імпортерами пального з європейських країн.
       Наша логістика відбувається власним автомобільним транспортом,
       тому ми можемо забезпечити своєчасність доставки та гарантувати якість нашого палива.  
       Команда ТОВ «КАРБОН-ПЛЮС» високо цінує свою репутацію, тому завжди виконує свої зобов’язання перед клієнтами вчасно та якісно.
    `,
    btnText: "Оптові продажі",
  },
  {
    key: "desc_quality",
    imageSrc: "/images/terminal.png",
    url: ROUTES.QUALITY,
    text: `
      Місією нашої компанії є забезпечення високоякісним паливом клієнтів,
      які є представниками різних напрямків галузей економіки нашої країни.
      При цьому дотримуючись принципів прозорих, відкритих відносин з нашими
      клієнтами та партнерами для довгострокової взаємовигідної співпраці.
    `,
    btnText: "Якість продукції",
  },
];

export const Home: React.FC = () => {
  const navigate = useNavigate();

  const pageTitle: string = "Карбон-Плюс";

  const navigateToUrl = (url: string) => {
    navigate(url);
  };

  useEffect(() => {
    document.title = pageTitle;
    window.scrollTo(0, 0);

    window.addEventListener("scroll", fadeOnScroll);

    return () => window.removeEventListener("scroll", fadeOnScroll, false);
  }, []);

  return (
    <div className="Home">
      <div className="container-with-bg">
        <div>
          <h1>Якісно. Прозоро. Доступно</h1>
        </div>
      </div>
      {sections.map((section: ISection) => (
        <section key={section.key} className="section">
          <div className="section__container">
            <div className="block">
              <img src={section.imageSrc} alt="placeholder" />
            </div>
            <div className="block">
              <h4>{section.text}</h4>
              <Button onClick={() => navigateToUrl(section.url)}>
                {section.btnText.toUpperCase()}
              </Button>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};
