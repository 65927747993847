import React, { useEffect } from "react";
import "./css/Sales.css";
import { fadeOnScroll } from "../../helpers/animations";

export const Sales: React.FC<any> = () => {
  const pageTitle: string = "Оптові продажі";

  useEffect(() => {
    document.title = pageTitle;
    window.scrollTo(0, 0);

    fadeOnScroll();
  }, []);

  return (
    <div className="Sales">
      <section className="section">
        <div className="section__container">
          <div className="block">
            <img src="/images/sales.jpeg" alt="sales" />
          </div>
          <div className="block">
            <h5>
              Працюючи з нашою компанією, Ви отримуєте якісні нафтопродукти в
              короткі терміни та за конкурентною ціною.
            </h5>
            <h5>
              Нашим оптовим покупцям пропонуємо доставку палива від 2000 л, яка
              забезпечить Ваш бізнес неперервним рухом вперед.
            </h5>
            <h5>
              Завдяки нашій компанії, Ви можете бути впевнені, що Ваші запаси
              палива завжди будуть вчасно доставлені до Вас власним автопарком
              бензовозів.
            </h5>
            <h5>
              Ми розуміємо, наскільки важливо для Вас мати доступ до надійного
              партнера, який гарантує швидку та ефективну доставку. Наша команда
              фахівців працює на результат та завжди ставить Ваші потреби на
              перше місце. Ми докладаємо максимум зусиль, щоб Ви могли
              сконцентруватися на своєму бізнесі, знаючи, що паливо буде завжди
              доступним.
            </h5>
          </div>
        </div>
      </section>
    </div>
  );
};
