import React, { Component, Suspense } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { IRoutes, ROUTES } from "./helpers/Routes";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Home } from "./components/Home/Home";
import { Quality } from "./components/Quality/Quality";
import { ContactUs } from "./components/ContactUs/ContactUs";
import { Charity } from "./components/Charity/Charity";
import { AboutUs } from "./components/AboutUs/AboutUs";
import { GasStation } from "./components/GasStation/GasStation";
import { Sales } from "./components/Sales/Sales";

export default class App extends Component<any, any> {
  render() {
    const routes: IRoutes = ROUTES;

    return (
      <HashRouter basename={window.location.pathname || " "}>
        <div className="App">
          <Header />
          <div className="App--container">
            <Suspense fallback={<></>}>
              <Routes>
                <Route path={routes.MAIN} element={<Home />} />
                <Route path={routes.GAS_STATION} element={<GasStation />} />
                <Route path={routes.WHOLESALE_SALES} element={<Sales />} />
                <Route path={routes.QUALITY} element={<Quality />} />
                <Route path={routes.PARTNERS} element={<Home />} />
                <Route path={routes.CHARITY} element={<Charity />} />
                <Route path={routes.ABOUT_US} element={<AboutUs />} />
                <Route path={routes.CONTACT_US} element={<ContactUs />} />
                {/*<Route element={ErrorPage} />*/}
              </Routes>
            </Suspense>
          </div>
          <Footer />
        </div>
      </HashRouter>
    );
  }
}
