import React from "react";
import { ImageList, ImageListItem } from "@mui/material";
import "./css/ImageListComponent.css";

export interface IImageList {
  itemsData: Array<IImageListItem>;
}

export interface IImageListItem {
  img: string;
  title: string;
  rows?: number;
  cols?: number;
  contain?: boolean;
}

export const ImageListComponent: React.FC<IImageList> = ({
  itemsData,
}: IImageList) => {
  const srcset = (
    image: string,
    size: number,
    rows: number = 1,
    cols: number = 1,
  ): any => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  };

  return (
    <div className="ImageListComponent">
      <ImageList
        sx={{ width: "100%", height: "100%" }}
        variant="quilted"
        cols={4}
        rowHeight={121}
      >
        {itemsData.map((item: IImageListItem) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              {...srcset(item.img, 121, item.rows, item.cols)}
              className={item.contain ? "img-contain" : ""}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};
