import React, { ReactNode, useEffect } from "react";
import "./css/AboutUs.css";
import { v4 as uuid } from "uuid";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PeopleIcon from "@mui/icons-material/People";
import ReviewsIcon from "@mui/icons-material/Reviews";

interface IStatItem {
  icon: ReactNode;
  text: Array<string>;
}

const sx = {
  height: "48px",
  width: "48px",
  color: "#fff",
  borderRadius: "50%",
  p: "8px",
  bgcolor: "#FFBB64",
};

const statsItems: Array<IStatItem> = [
  {
    icon: <ReviewsIcon sx={sx} />,
    text: ["Працюємо більше", "8-ми", "років на ринку"],
  },
  {
    icon: <PeopleIcon sx={sx} />,
    text: ["Маємо", "тисячі", "постійних клієнтів"],
  },
  {
    icon: <LocalGasStationIcon sx={sx} />,
    text: ["Реалізуємо понад", "5 млн л", "пального на рік"],
  },
];

export const AboutUs: React.FC = () => {
  const pageTitle: string = "Про нас";

  useEffect(() => {
    document.title = pageTitle;
  }, []);

  return (
    <div className="AboutUs">
      <h5>
        <b>
          <i>ТОВ «КАРБОН-ПЛЮС»</i>
        </b>{" "}
        - постачальник високоякісних нафтопродуктів (дизельного палива та
        бензинів), які відповідають Українським та Міжнародним стандартам
        якості. Компанія успішно здійснює оптовий продаж сертифікованих
        нафтопродуктів, які супроводжуються необхідним пакетом документів, від
        провідних світових виробників. Багаторічний досвід дозволяє гарантувати
        високу якість палива та його безпечне транспортування у будь-яку точку
        країни. Гарантуємо своєчасне виконання графіка поставок.
      </h5>
      <div className="stats">
        {statsItems.map((item: IStatItem) => (
          <div className="block" key={uuid()}>
            <>{item.icon}</>
            <h4 className="text">{item.text[0]}</h4>
            <h4 className="counter">{item.text[1]}</h4>
            <h4 className="text">{item.text[2]}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};
