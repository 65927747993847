export interface IRoutes {
    MAIN: string;
    GAS_STATION: string;
    WHOLESALE_SALES: string;
    QUALITY: string;
    PARTNERS: string;
    CHARITY: string;
    ABOUT_US: string;
    CONTACT_US: string;
}

export const ROUTES: IRoutes = {
    MAIN: '/',
    GAS_STATION: '/gas-station',
    WHOLESALE_SALES: '/wholesale-sales',
    QUALITY: '/quality',
    PARTNERS: '/partners',
    CHARITY: '/charity',
    ABOUT_US: '/about-us',
    CONTACT_US: '/contact-us'
};
