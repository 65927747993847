import React from "react";
import "./css/YoutubeEmbedded.css";

interface IYoutubeEmbeddedProps { embeddedId: string }

export const YoutubeEmbedded: React.FC<IYoutubeEmbeddedProps> = ({ embeddedId }: IYoutubeEmbeddedProps) => (
    <div className="YoutubeEmbedded">
        <iframe src={`https://www.youtube.com/embed/${embeddedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
    </div>
);
