import React from "react";
import "./css/Footer.css";

const email: string = "karbonplyus@gmail.com ";
const phones: Array<string> = ["0472565656", "0982781886", "0965319643"];

export const Footer: React.FC = () => {
  const year: number = new Date().getFullYear();

  return (
    <div className="Footer">
      <div className="top-container">
        <div className="container">
          <img
            className="logo-img m-b-15"
            src="/images/carbon-logo.png"
            alt="Логотип Карбон-Плюс"
          />
          <h5 className="m-b-15">Паливо оптом і у роздріб</h5>
        </div>
        <div className="container"></div>
        <div className="container">
          <h3 className="title m-b-15">Контакти</h3>
          <p className="m-b-15">
            вулиця Нечуя-Левицького, 16
            <span className="city">м. Черкаси</span>
          </p>
          <p className="m-b-15">
            <a className="mail-link" href={email}>
              {email}
            </a>
          </p>
          <p>
            {phones.map((phone: string, index: number) => (
              <React.Fragment key={phone}>
                <a className="mail-link" href={`tel:${phone}`}>
                  {phone}
                </a>
                {index !== phones.length - 1 && <br />}
              </React.Fragment>
            ))}
          </p>
        </div>
      </div>
      <span className="copyright">
        {year} Carbon-Плюс. Усі права захищені.&nbsp;
        <span className="author"></span>
        Designed by Lirys
      </span>
    </div>
  );
};
